<template lang="pug">
  .page.in-development-page
    h1 Раздел находится в разработке

</template>

<script>

  export default {
    name: 'InDevelopment',
    components: {},
    data() {
      return {
      }
    },
    computed: {
      lang() {
        return this.$store.getters.language
      },
    },
  }
</script>
<style rel="stylesheet/scss" lang="sass">
  @import "@/assets/sass/mixin.scss"
  .in-development-page
    padding-left: 30px
</style>
